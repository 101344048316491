<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Model Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-form ref="mainForm">
                <v-card>
                    <v-toolbar dark color="panel1">
                        <v-toolbar-title>Info</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field outlined v-model="form.em_code" label="Code"></v-text-field>
                                <v-text-field outlined v-model="form.em_name" label="Name"></v-text-field>
                                <v-select
                                    outlined
                                    v-model = "form.transaction_type_code"
                                    :items="transactionTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="Transaction Channel"
                                    standard
                                ></v-select>

                                <v-select
                                    outlined
                                    v-model = "form.earned_campaign_id"
                                    :items="campaignList"
                                    item-text="ec_name"
                                    item-value="earned_campaign_id"
                                    label="Campaign Name"
                                    standard
                                ></v-select>
                                <v-select
                                    outlined
                                    v-model = "form.status"
                                    :items="statusList"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                ></v-select>
                                <v-btn class="success" @click="save"><v-icon>save</v-icon> Save</v-btn>
                            </v-col>                            
                        </v-row>
                    </v-container>
                
                </v-card>
                <v-card class="mt-3">
                    <v-toolbar dark  color="panel2">
                        <v-toolbar-title>Availability</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        
                                        :value="form.end_date"
                                        label="End Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>

                            <v-menu v-model="tpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        :value="form.start_time"
                                        label="Start Time"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        use-seconds
                                    ></v-text-field>
                                </template>
                                <v-time-picker v-model="form.start_time" @change="tpicker.menu1 = false"
                                    use-seconds
                                    full-width
                                >

                                </v-time-picker>
                            </v-menu>
                            <v-menu v-model="tpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        :value="form.end_time"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        
                                    ></v-text-field>
                                </template>
                                <v-time-picker v-model="form.end_time" @change="tpicker.menu2 = false"
                                    use-seconds
                                    full-width
                                ></v-time-picker>
                            </v-menu>
                            <v-checkbox v-for="day in dayCheckBox" :key="day.fieldName"
                                :input-value="form[day.fieldName]==1?true:false"
                                :label="day.name"
                                @change="changeState($event,day.fieldName)"
                                dense
                            ></v-checkbox>
                            <v-btn class="success" @click="save"><v-icon>save</v-icon> Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-card class="mt-3">
                    <v-toolbar dark  color="panel3">
                        <v-toolbar-title>Type</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-select
                                    outlined
                                    v-model = "form.em_type_code"
                                    :items="emList"
                                    item-text="description"
                                    item-value="code"
                                    label="Model Type"
                                    standard
                                ></v-select>
                                <v-select
                                    outlined
                                    v-model = "form.calc_type_code"
                                    :items="calcList"
                                    item-text="description"
                                    item-value="code"
                                    label="Calculation Type"
                                    standard
                                ></v-select>
                                <v-text-field outlined v-model="form.purchase_amount" label="Purchased Amount"></v-text-field>
                                <v-text-field outlined v-model="form.purchase_quantity" label="Purchased Quantity"></v-text-field>
                                <v-text-field outlined v-model="form.points_earned" label="Point Earned"></v-text-field>
                                <v-text-field outlined v-model="form.percentage" label="Percentage"></v-text-field>
                                <v-btn class="success" @click="save"><v-icon>save</v-icon> Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-card class="mt-3">
                    <v-toolbar dark class="panel1">
                        <v-toolbar-title>Conditions</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field outlined v-model="form.count_max_limit" label="Transaction Count Limit (0-unlimited)"></v-text-field>
                                <v-text-field outlined v-model="form.amt_min" label="Min Transaction Amount"></v-text-field>
                                <v-text-field outlined v-model="form.amt_max" label="Max Transaction Amount"></v-text-field>
                                <v-btn class="success" @click="save"><v-icon>save</v-icon> Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                </v-form>
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panelSysInfo">
                        <v-toolbar-title>System Info</v-toolbar-title>
                    </v-toolbar>

                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field disabled v-for="info in  systemInfo" :key="info.name" outlined v-model="form[info.fieldName]" :label="info.name"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApEarnedService from '@/services/ApEarnedService'
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Catalogue',disabled: false,href:'/catalogue',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            statusList:[],
            campaignList:[],
            emList:[],
            calcList:[],
            transactionTypeList:[],
           
            form:[],
            logo:'',

            cpFiles:{
                file_type:'image',
                route_type:'catalogues',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            tpicker:{menu1: false,menu2: false,},
            systemInfo:[
                {name:"ID",fieldName:"earned_model_id"},
                {name:"Created Date",fieldName:"created_date"},
                {name:"Created At",fieldName:"created_at"},
                {name:"Created By",fieldName:"created_by"},
                {name:"Updated Date",fieldName:"updated_date"},
                {name:"Updated At",fieldName:"updated_at"},
                {name:"Updated By",fieldName:"updated_by"},
            ],
            checkBox:[],
            dayCheckBox:[
                {name:"Moday",fieldName:"day_monday",value:null},
                {name:"Tuesday",fieldName:"day_tuesday",value:null},
                {name:"Wednesday",fieldName:"day_wednesday",value:null},
                {name:"Thursday",fieldName:"day_thursday",value:null},
                {name:"Friday",fieldName:"day_friday",value:null},
                {name:"Saturday",fieldName:"day_saturday",value:null},
                {name:"Sunday",fieldName:"day_sunday",value:null},
            ],


    }
  },
  async created() {
      this.form.earned_model_id = this.$route.params.earned_model_id;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        let title = 'System';
        this.form = {login_id: "",earned_model_id: this.form.earned_model_id}
        try{
            ApEarnedService.view_earned_model(this.form).then((res) => {
                this.form = res.data.data;
                this.statusList = res.data.statusList; 
                this.campaignList = res.data.campaignList; 
                this.emList = res.data.emList;
                this.calcList = res.data.calcList;                
                this.transactionTypeList = res.data.transactionTypeList;
                
            }).catch((e)=>{
                    //console.log(e.response);
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
                ;
        } catch(e) {
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
        } 

    },
    async save(){
        let title = 'Update Pts Model';
        if(this.$refs.mainForm.validate()) {
            try{
                ApEarnedService.update_earned_model(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success',title,'Success');
                }).catch((e)=>{
                        //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                })
                    ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error' + e);
            }             
        }            

    },
    
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'catalogues';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    dlgClose(){
        this.visible = false;        
    },
    changeState(e,key){
        if(e)
            this.form[key] = "1";
        else
            this.form[key] = "0";
    }
  }
}
</script>