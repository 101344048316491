import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApEarnedService {


    view_earned_model(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/view_earned_model", form);

    }
    
    list_earned_model(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/list_earned_model", form);

    }

    update_earned_model(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/update_earned_model", form);

    }        


    list_earned_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/list_earned_group", form);

    }
    
    list_earned_campaign(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/list_earned_campaign", form);

    }
    
    insert_earned_campaign(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/insert_earned_campaign", form);

    }
    
    insert_earned_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/insert_earned_group", form);

    }    

    insert_earned_model(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/insert_earned_model", form);

    }        

    
    update_earned_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/update_earned_group", form);

    }        

    view_earned_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/view_earned_group", form);

    }
    
    view_earned_campaign(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/view_earned_campaign", form);

    }
    
    update_earned_campaign(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_earned/update_earned_campaign", form);

    }  
    




}

    
 

export default new ApEarnedService();